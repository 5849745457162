import React from "react";

import photo_sigl from "../../../../assets/PROMO_2025/sigl.jpg";

import photo_noel from "../../../../assets/PROMO_2025/noel2025.png"; // REMOVE AFTER NOEL
import photo_ol1pie from "../../../../assets/PROMO_2025/ol1pie.jpg";
import photo_7ptre from "../../../../assets/PROMO_2025/7ptre.jpg";
import photo_7ptre_opco from "../../../../assets/PROMO_2025/7ptre-opco-soutenance.jpg";
import photo_ligne8 from "../../../../assets/PROMO_2025/ligne-8.jpg";
import photo_flambant9 from "../../../../assets/PROMO_2025/flambant9.jpg";
import photo_b4r from "../../../../assets/PROMO_2025/b4r.jpg";
import photo_m3dals from "../../../../assets/PROMO_2025/m3dals.jpg";
import photo_2gether from "../../../../assets/PROMO_2025/2gether.jpg";
import photo_soiree from "../../../../assets/PROMO_2025/soiree.jpg";
import photo_octave_dodo from "../../../../assets/PROMO_2025/octave-dodo.jpg";
import photo_10ers from "../../../../assets/PROMO_2025/10ers.png";
import photo_2025 from "../../../../assets/PROMO_2025/2025.jpg";

const data = [
    {
        src: photo_2025,
    },
    {
        src: photo_sigl,
    },
    {
        src: photo_7ptre_opco,
    },
    {
        src: photo_10ers,
    },
    {
        src: photo_noel, // REMOVE AFTER NOEL
    },
    {
        src: photo_ol1pie,
    },
    {
        src: photo_2gether,
    },
    {
        src: photo_m3dals,
    },
    {
        src: photo_b4r,
    },
    {
        src: photo_7ptre,
    },
    {
        src: photo_ligne8,
    },
    {
        src: photo_flambant9,
    },
    {
        src: photo_soiree,
    },
    {
        src: photo_octave_dodo
    }
];

const Carousel = () => {
    return (
        <div className="card">
            <div className="card-body p-1">
                <div className="row">
                    <div className="col">
                        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                {data.map((item, index) => (
                                    <div
                                        key={index}
                                        className={`carousel-item${index === 0 ? ' active' : ''}`}
                                        data-bs-interval="4000"
                                    >
                                        <img
                                            src={item.src}
                                            alt={`${index + 1}`}
                                            className="d-flex w-100"
                                            style={{ maxWidth: '400px', margin: '0 auto' }}
                                        />
                                    </div>
                                ))}
                            </div>
                            <a
                                className="carousel-control-prev"
                                href="#myCarousel"
                                role="button"
                                data-bs-slide="prev"
                            >
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </a>
                            <a
                                className="carousel-control-next"
                                href="#myCarousel"
                                role="button"
                                data-bs-slide="next"
                            >
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Carousel;
